import { kebabCase } from 'lodash';

export function getUid(prefix) {
  return `${prefix}-${Math.random().toString(16).slice(2)}`;
}

export const mountFieldPayload = (fields) => {
  return fields.map((payload) => {
    const name = payload.name || getUid(kebabCase(payload.label));

    const { kind } = payload;
    let { header, paragraph } = payload;

    if (kind !== 'paragraph') paragraph = null;
    if (kind !== 'header') header = null;

    return {
      ...payload,
      name,
      type: payload.kind,
      header,
      paragraph,
    };
  });
};
